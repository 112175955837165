























import { MessageService } from "@/shared/services/message-service";
import { UtilsString } from "@/utils/utils-string";
import { Component, Prop, Vue } from "vue-property-decorator";
import { BaseDto } from "@/shared/dtos/base-dto";
import { ssmHttpService } from "@/shared/services/http-service";
import pantalla_modo_listaModule from "@/store/modules/pantalla_modo_lista-module";
import { API } from "@/shared/api";
import { pantalla_modo_lista } from "@/shared/dtos/dynamic_tables/pantalla_modo_lista";
@Component({
  components: {
    DynamicTable: () => import("@/views/dynamic_tables/dynamic_table.vue"),
  },
})
export default class FullDynamicTable extends Vue {
  @Prop({ default: 0 }) id_tabla!: number;
  @Prop({ default: function() {} }) EventMethod!: (...args: any[]) => any[];
  @Prop({ default: true }) shwtoolbar!: boolean;
  @Prop({ default: "" }) search!: string;

  public pantalla_modo_lista: pantalla_modo_lista = new pantalla_modo_lista();
  created() {
    pantalla_modo_listaModule
      .getpantalla_modo_lista(this.get_id_tabla)
      .then((x: pantalla_modo_lista) => (this.pantalla_modo_lista = x));
  }
  get get_id_tabla() {
    let id = this.$route.meta.id;
    if (id === undefined) {
      id = this.$route.params.id;
    }
    if (this.id_tabla > 0) {
      id = this.id_tabla;
    }
    return id;
  }

  get permission_new() {
    if (
      this.pantalla_modo_lista.pantalla === null ||
      this.pantalla_modo_lista.pantalla === undefined
    ) {
      return [];
    }
    if (
      UtilsString.ValueOf(this.pantalla_modo_lista.pantalla.permission_new) ===
      ""
    ) {
      return [];
    }
    return [
      UtilsString.ValueOf(this.pantalla_modo_lista.pantalla.permission_new),
    ];
  }

  get permission_update() {
    if (
      this.pantalla_modo_lista.pantalla === null ||
      this.pantalla_modo_lista.pantalla === undefined
    ) {
      return [];
    }
    if (
      UtilsString.ValueOf(
        this.pantalla_modo_lista.pantalla.permission_update
      ) === ""
    ) {
      return [];
    }
    return [
      UtilsString.ValueOf(this.pantalla_modo_lista.pantalla.permission_update),
    ];
  }

  get permission_delete() {
    if (
      this.pantalla_modo_lista.pantalla === null ||
      this.pantalla_modo_lista.pantalla === undefined
    ) {
      return [];
    }
    if (
      UtilsString.ValueOf(
        this.pantalla_modo_lista.pantalla.permission_delete
      ) === ""
    ) {
      return [];
    }
    return [
      UtilsString.ValueOf(this.pantalla_modo_lista.pantalla.permission_delete),
    ];
  }

  nuevo() {
    this.abrir_formulario("-1", "new");
  }

  editar(obj: BaseDto) {
    this.abrir_formulario(UtilsString.ValueOf(obj.id), "edit");
  }

  async eliminar(obj: BaseDto) {
    await ssmHttpService
      .delete(
        API.webApiBase +
          this.pantalla_modo_lista.pantalla.api_call +
          "/" +
          obj.id,
        null,
        false
      )
      .then(() => {
        //@ts-ignore
        this.$refs["data_table"].refresh();
      })
      .catch((x) => {
        var mensaje = x.response.data.message;
        if (UtilsString.IsNullOrWhiteSpace(mensaje)) {
          mensaje =
            "No se ha podido eliminar, hay datos asociados a este registro";
        }
        MessageService.toast(mensaje, MessageService.TypeWarning);
      });
  }
  refresh() {
    //@ts-ignore
    this.$refs["data_table"].refresh();
  }
  abrir_formulario(id: string, event: string) {
    if (
      UtilsString.IsNullOrWhiteSpace(
        this.pantalla_modo_lista.pantalla.router_name
      )
    ) {
      this.$emit(event, id);
    } else {
      this.$router.push({
        name: this.pantalla_modo_lista.pantalla.router_name,
        params: { id: id },
      });
    }
  }
}
